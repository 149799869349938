import React, { Component } from 'react';




class notfound extends Component {
  render() {


    return (
        <div className="pagenotfound text-center">
             <div className="container-fluid">
           <div className="page_not">
           <h1> 404 </h1>    
                <p> Page Not Found</p>
           </div>
            </div>
         </div>
    );
  }
}

export default notfound;